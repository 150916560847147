<template>
  <div id="signup">
    <section>
      <div class="col1">
        <router-link to="/us/en/"><h1>Wellthmark</h1></router-link>
        <router-link to="/portfolio/all"><h3>Your health is your <span class="sloganHighlight">wellth</span>. Bench<span class="sloganHighlight">mark</span> it here.</h3></router-link>
      </div>
      <div class="col2">
        <form @submit.prevent>
          <h2>Let's get started!</h2>
          <div>
            <label for="name">Name</label>
            <input v-model.trim="signupForm.name" type="text" placeholder="What would you like to be called?" id="name" />
          </div>
          <div>
            <label for="gender">Sex</label>
            <!-- <input v-model.trim="signupForm.gender" type="text" placeholder="Prefer not to say" id="title" /> -->
            <select v-model.trim="signupForm.gender" name="gender">
              <option disabled value="">Please select one</option>
              <option value="female">Female</option>
              <option value="male">Male</option>
              <option value="prefer">Prefer not to say</option>
            </select>
          </div>
          <!-- <div>
            <label for="dob">Date of Birth</label>
            <input type='date' v-model.trim="signupForm.dob" :placeholder="signupForm.dob" :max="dateMax" id="date" />
          </div> -->
          <!-- <div>
            <label for="age">Age</label>
            <input v-model.trim="signupForm.age" type="number" placeholder="How many years old are you?" id="age" />
          </div> -->
          <div>
            <label for="age">Birth Year</label>
            <input v-model.trim="signupForm.age" type="number" placeholder="In what year were you born?" id="age" />
          </div>
          <div>
            <label for="email">Email</label>
            <input v-model.trim="signupForm.email" type="text" placeholder="me@email.com" id="email" />
          </div>
          <div>
            <label for="password1">Password</label>
            <input v-model.trim="signupForm.password" type="password" placeholder="********" id="password1" />
          </div>
          <div class="checkbox">
            <input type="checkbox" id="checkbox" v-model="checked">
            <a @click="togglePrivacyPolicy()">I agree to the Privacy Policy and Terms & Conditions</a>
          </div>
          <!-- <button @click="signup()" :disabled="!checked || signupForm.email === '' || signupForm.name === '' || signupForm.gender === '' || new Date(signupForm.date) > new Date() || signupForm.password.length < 8" class="button">Sign Up</button> -->
          <button @click="signup()" :disabled="!checked || signupForm.email === '' || signupForm.name === '' || signupForm.gender === '' || signupForm.age === '' || signupForm.age < 1900 || signupForm.age > currentYear || signupForm.password.length < 8" class="button">Sign Up</button>
          <div class="extras">
            <a @click="toggleForm()">Already have an account?</a>
            <!-- <a @click="togglePrivacyPolicy()">Privacy & Terms</a> -->
            <!-- <a @click="toggleTerms()">Terms & Conditions</a> -->
          </div>
        </form>
      </div>
    </section>
    <transition name="fade">
      <div v-if="showPrivacyPolicy" class="modal">
        <div class="modal-content">
          <div @click="togglePrivacyPolicy()" class="close">close</div>
          <h3>Privacy & Terms</h3>
          <hr>
          <strong>Privacy Policy</strong> <p>
            Wellthmark built the Wellthmark app as
            a Freemium app. This SERVICE is provided by
            Wellthmark at no cost and is intended for use as
            is.
          </p> <p>
            This page is used to inform visitors regarding our
            policies with the collection, use, and disclosure of Personal
            Information if anyone decided to use our Service.
          </p> <p>
            If you choose to use our Service, then you agree to
            the collection and use of information in relation to this
            policy. The Personal Information that we collect is
            used for providing and improving the Service. We will not use or share your information with
            anyone except as described in this Privacy Policy.
          </p> <p>
            The terms used in this Privacy Policy have the same meanings
            as in our Terms and Conditions, which is accessible at
            Wellthmark unless otherwise defined in this Privacy Policy.
          </p> <p><strong>Information Collection and Use</strong></p> <p>
            For a better experience, while using our Service, we
            may require you to provide us with certain personally
            identifiable information, including but not limited to Name, Email, Gender, Date of Birth, Health and Wellness Data. The information that
            we request will be retained by us and used as described in this privacy policy.
          </p> <div><p>
              The app does use third party services that may collect
              information used to identify you.
            </p> <p>
              Link to privacy policy of third party service providers used
              by the app
            </p> <ul><!----><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p><strong>Log Data</strong></p> <p>
            We want to inform you that whenever you
            use our Service, in a case of an error in the app
            we collect data and information (through third party
            products) on your phone called Log Data. This Log Data may
            include information such as your device Internet Protocol
            (“IP”) address, device name, operating system version, the
            configuration of the app when utilizing our Service,
            the time and date of your use of the Service, and other
            statistics.
          </p> <p><strong>Cookies</strong></p> <p>
            Cookies are files with a small amount of data that are
            commonly used as anonymous unique identifiers. These are sent
            to your browser from the websites that you visit and are
            stored on your device's internal memory.
          </p> <p>
            This Service does not use these “cookies” explicitly. However,
            the app may use third party code and libraries that use
            “cookies” to collect information and improve their services.
            You have the option to either accept or refuse these cookies
            and know when a cookie is being sent to your device. If you
            choose to refuse our cookies, you may not be able to use some
            portions of this Service.
          </p> <p><strong>Service Providers</strong></p> <p>
            We may employ third-party companies and
            individuals due to the following reasons:
          </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
            We want to inform users of this Service
            that these third parties have access to your Personal
            Information. The reason is to perform the tasks assigned to
            them on our behalf. However, they are obligated not to
            disclose or use the information for any other purpose.
          </p> <p><strong>Security</strong></p> <p>
            We value your trust in providing us your
            Personal Information, thus we are striving to use commercially
            acceptable means of protecting it. But remember that no method
            of transmission over the internet, or method of electronic
            storage is 100% secure and reliable, and we cannot
            guarantee its absolute security.
          </p> <p><strong>Links to Other Sites</strong></p> <p>
            This Service may contain links to other sites. If you click on
            a third-party link, you will be directed to that site. Note
            that these external sites are not operated by us.
            Therefore, we strongly advise you to review the
            Privacy Policy of these websites. We have
            no control over and assume no responsibility for the content,
            privacy policies, or practices of any third-party sites or
            services.
          </p> <p><strong>Children’s Privacy</strong></p> <p>
            These Services do not address anyone under the age of 13.
            We do not knowingly collect personally
            identifiable information from children under 13. In the case
            we discover that a child under 13 has provided
            us with personal information, we immediately
            delete this from our servers. If you are a parent or guardian
            and you are aware that your child has provided us with
            personal information, please contact us so that
            we will be able to do necessary actions.
          </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
            We may update our Privacy Policy from
            time to time. Thus, you are advised to review this page
            periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on
            this page.
          </p> <p>This policy is effective as of 2021-02-03</p> <p><strong>Contact Us</strong></p> <p>
            If you have any questions or suggestions about our
            Privacy Policy, do not hesitate to contact us at help@wellthmark.com.
          </p>
          <strong>Terms &amp; Conditions</strong> <p>
            By downloading or using the app, these terms will
            automatically apply to you – you should make sure therefore
            that you read them carefully before using the app. You’re not
            allowed to copy, or modify the app, any part of the app, or
            our trademarks in any way. You’re not allowed to attempt to
            extract the source code of the app, and you also shouldn’t try
            to translate the app into other languages, or make derivative
            versions. The app itself, and all the trade marks, copyright,
            database rights and other intellectual property rights related
            to it, still belong to Wellthmark.
          </p> <p>
            Wellthmark is committed to ensuring that the app is
            as useful and efficient as possible. For that reason, we
            reserve the right to make changes to the app or to charge for
            its services, at any time and for any reason. We will never
            charge you for the app or its services without making it very
            clear to you exactly what you’re paying for.
          </p> <p>
            No part of Wellthmark should be construed as professional advice. 
            Any and all medical, health, wellness, or other professional 
            related content is presented for 
            informational purposes only. No actions should be taken on the 
            basis of this information without first consulting 
            a professional.
          </p> <div><p>
              The app does use third party services that declare their own
              Terms and Conditions.
            </p> <p>
              Link to Terms and Conditions of third party service
              providers used by the app
            </p> <ul><!----><li><a href="https://developers.google.com/admob/terms" target="_blank" rel="noopener noreferrer">AdMob</a></li><li><a href="https://firebase.google.com/terms/analytics" target="_blank" rel="noopener noreferrer">Google Analytics for Firebase</a></li><li><a href="https://firebase.google.com/terms/crashlytics" target="_blank" rel="noopener noreferrer">Firebase Crashlytics</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div> <p>
            You should be aware that there are certain things that
            Wellthmark will not take responsibility for. Certain
            functions of the app will require the app to have an active
            internet connection. The connection can be Wi-Fi, or provided
            by your mobile network provider, but Wellthmark
            cannot take responsibility for the app not working at full
            functionality if you don’t have access to Wi-Fi, and you don’t
            have any of your data allowance left.
          </p> <p></p> <p>
            If you’re using the app outside of an area with Wi-Fi, you
            should remember that your terms of the agreement with your
            mobile network provider will still apply. As a result, you may
            be charged by your mobile provider for the cost of data for
            the duration of the connection while accessing the app, or
            other third party charges. In using the app, you’re accepting
            responsibility for any such charges, including roaming data
            charges if you use the app outside of your home territory
            (i.e. region or country) without turning off data roaming. If
            you are not the bill payer for the device on which you’re
            using the app, please be aware that we assume that you have
            received permission from the bill payer for using the app.
          </p> <p>
            Along the same lines, Wellthmark cannot always take
            responsibility for the way you use the app i.e. You need to
            make sure that your device stays charged – if it runs out of
            battery and you can’t turn it on to avail the Service,
            Wellthmark cannot accept responsibility.
          </p> <p>
            With respect to Wellthmark’s responsibility for your
            use of the app, when you’re using the app, it’s important to
            bear in mind that although we endeavour to ensure that it is
            updated and correct at all times, we do rely on third parties
            to provide information to us so that we can make it available
            to you. Wellthmark accepts no liability for any
            loss, direct or indirect, you experience as a result of
            relying wholly on this functionality of the app.
          </p> <p>
            At some point, we may wish to update the app. The app is
            currently available on  – the requirements for
            system(and for any additional systems we
            decide to extend the availability of the app to) may change,
            and you’ll need to download the updates if you want to keep
            using the app. Wellthmark does not promise that it
            will always update the app so that it is relevant to you
            and/or works with the  version that you have
            installed on your device. However, you promise to always
            accept updates to the application when offered to you, We may
            also wish to stop providing the app, and may terminate use of
            it at any time without giving notice of termination to you.
            Unless we tell you otherwise, upon any termination, (a) the
            rights and licenses granted to you in these terms will end;
            (b) you must stop using the app, and (if needed) delete it
            from your device.
          </p> <p><strong>Changes to This Terms and Conditions</strong></p> <p>
            We may update our Terms and Conditions
            from time to time. Thus, you are advised to review this page
            periodically for any changes. We will
            notify you of any changes by posting the new Terms and
            Conditions on this page.
          </p> <p>
            These terms and conditions are effective as of 2021-02-03
          </p> <p><strong>Contact Us</strong></p> <p>
            If you have any questions or suggestions about our
            Terms and Conditions, do not hesitate to contact us
            at help@wellthmark.com.
          </p>
        </div>
      </div>
    </transition>
    <!-- <transition name="fade">
      <div v-if="showTerms" class="modal">
        <div class="modal-content">
          <div @click="toggleTerms()" class="close">close</div>
          <h3>Terms & Conditions</h3>
          <hr>
          <p>Terms & conditions go here...</p>
        </div>
      </div>
    </transition> -->
  </div>
</template>

<script>
import moment from 'moment'

export default {
  data() {
    return {
      showPrivacyPolicy: false,
      showTerms: false,
      signupForm: {
        age: '',
        // dob: moment(new Date()).format('YYYY-MM-DD'),
        email: '',
        gender: '',
        name: '',
        password: ''
      },
      dateMax: moment(new Date()).format('YYYY-MM-DD'),
      checked: false
    }
  },
  computed: {
    currentYear() {
      return moment().year()
    }
  },
  methods: {
    toggleForm() {
      this.$router.push('/login')
    },
    togglePrivacyPolicy() {
      this.showPrivacyPolicy = !this.showPrivacyPolicy
    },
    // toggleTerms() {
    //   this.showTerms = !this.showTerms
    // },
    signup() {
      this.$store.dispatch('signup', {
        age: this.signupForm.age,
        // dob: this.signupForm.dob,
        email: this.signupForm.email,
        gender: this.signupForm.gender,
        name: this.signupForm.name,
        password: this.signupForm.password
      })
    }
  }
}
</script>

<style lang="scss" scoped>

// colors
$primary: #3D4D62;
$secondary: #7399C6;
$error: #ef5777;
$darkGreen: #1FA482;
$lightGreen: #5CD0B5;
$lightest: #e3f2fa;
$light: #c3d6e2;
$medium: #7399C6;
$dark: #3D4D62;
$white: #FFFFFF;
$deteriorating: #ef5777;
$improving: #1FA482;
$greyedOut: #787f85;

// fonts
$primaryFont: 'Playfair Display';
$myFontSize: 18px;
$myWidth: 680px;

// signup
#signup {
  background: linear-gradient(to right, $white 0%, $white 50%, $primary 50%, $primary 100%);

  @media screen and (max-width: 800px) {
    // height: 100vh;
    background: $primary;
  }

  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0;
    border: 0;
    padding: 0;

    .col1 {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5vh;

      h3 {
        color: $secondary;
        line-height: 1.5;
      }

      @media screen and (max-width: 800px) {
        display: none;
        // height: auto;
        // padding-top: 20vh;
      }
    }

    .col2 {
      flex: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 5vh;
      width: 100%;

      h2 {
        color: $white;
      }

      // @media screen and (max-width: 742px) {
      //   height: auto;
      //   padding-top: 20vh;
      // }
    }
  }

  .col1 {
    color: $white;
    @media screen and (max-width: 800px) {
      display: none;
    }

    p {
      max-width: 490px;
      margin-top: 2rem;
      line-height: 1.8;
    }

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .col2 {
    h1 {
      margin-bottom: 2rem;
    }

    form {
      max-width: 450px;
      margin: 0 auto;
      width: 100%;
    }

    .extras {
      float: right;
      text-align: right;
      padding: 1rem 0 0 0;

      a {
        display: block;
        margin-bottom: 0.5rem;
        color: $secondary;
        // padding: 0.5rem;

        &:hover {
          // background-color: #000;
          color: $lightest;
        }
      }
    }

    .password-reset {
      h1 {
        margin-bottom: 1rem;
      }

      p {
        margin-bottom: 2rem;
      }
    }
  }
}

// password reset
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(#333, 0.5);
  padding: 5rem 0;

  .modal-content {
    position: relative;
    margin: auto;
    width: 100%;
    height: 100%;
    max-width: 400px;
    background: #fff;
    padding: 40px;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(#333, 0.5);
    z-index: 999;
    overflow: scroll;

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      padding: 5px;
      cursor: pointer;
      transition: 0.15s;

      &:hover {
        color: #000;
      }
    }

    h3 {
      margin: 0;
    }

    p {
      margin: 15px 0;
    }

    .error {
      color: tomato;
      font-size: 12px;
    }

    .button {
      width: 100%;
    }
  }
}

label {
  color: $secondary;
}

h2 {
  padding: 1rem 0;
}

button {
  margin: 1rem 0;
  color: $primary;
  background: $white;
  border: 0 solid $primary;
  padding: 0.5rem 1rem;
  border-radius: 7px;
  box-shadow: 0 0 0 0 rgba($dark, 0.5);
  cursor: pointer;

  &:hover {
    background: $lightest;
  }

  &:disabled {
    opacity: 0.5;
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  * {
    width: auto;
  }

  label {
    // margin: 0 0 1rem 0.5rem;
    margin-left: 0.5rem;
  }

  a {
    display: block;
    margin-bottom: 0.5rem;
    margin-left: 0.25rem;
    color: $secondary;
    text-decoration: underline;
    // padding: 0.5rem;

    &:hover {
      // background-color: #000;
      color: $lightest;
    }
  }
}

</style>